import { Link } from "react-router-dom";
import {
  ImFacebook,
  ImLinkedin2,
  ImInstagram,
  ImTwitter,
} from "react-icons/im";
import { MdEmail } from "react-icons/md";
import "./index.css";
const Footer = () => {
  const d = new Date();
  const currentYear = d.getFullYear();
  return (
    <div className="footer-bg">
      <div className="footer-first-sec">
        <a href="/" className="footer-site-title">
          Digiwave
        </a>
        <div className="social-media-div">
          <a href="#fb" className="social-media-icons">
            <ImFacebook />
          </a>
          <a href="#ins" className="social-media-icons">
            <ImInstagram />
          </a>
          <a href="#tw" className="social-media-icons">
            <ImTwitter />
          </a>
          <a href="#link" className="social-media-icons">
            <ImLinkedin2 />
          </a>
        </div>
        <div className="term-conditions-menu">
          <Link to="/term-and-conditions" className="footer-pages">
            Terms and conditions
          </Link>
          <Link to="/privacy-policy" className="footer-pages">
            Privacy policy
          </Link>
        </div>
      </div>
      <div className="email-icon">
        <MdEmail />
        <p>digiwave22@gmal.com</p>
      </div>
      <hr className="hr-line" />
      <div className="footer-last-sec">
        <p>&copy;{currentYear} digiwave. All rights reserved.</p>
      </div>
    </div>
  );
};
export default Footer;
