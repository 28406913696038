import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import "./index.css";
const Fbads = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const url = "https://digiwave.online/api/fbads.php";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setData(data);
      });
  }, []);
  return (
    <div className="fbads-bg-con">
      <Header />
      <div className="fbads-content">
        {data.map((each) => (
          <div key={each.id} className="card">
            <img src={each.image_url} alt="img-url" className="card-img-url" />
            <h2 className="card-title">{each.title}</h2>
            <a href={each.page_url} className="page-url-btn">
              <button>Visit Now</button>
            </a>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Fbads;
