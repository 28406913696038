import Header from "../Header";
import Footer from "../Footer";
import TopBtn from "../TopBtn";
import "./index.css";
const About = () => {
  return (
    <>
      <Header />
      <TopBtn />
      <div className="about-bg">
        <div className="about-first-sec">
          <h2 className="about-heading">My Journey...</h2>
          <p className="about-desc">
            Hi, I'm Rafeeq, Welcome to our digital marketing agency, I started
            this digital marketing agency business recently. where innovation
            meets strategy to propel your brand forward. With a focus on
            data-driven insights and creative solutions, we're dedicated to
            maximizing your online presence. Let's partner to unleash the power
            of digital marketing and achieve your business objectives together.
          </p>
          <div className="sign-div">
            <img
              src="https://digiwave.online/images/author-image.webp"
              alt="author-img"
              className="author-img-sm"
            />
            <p className="sign">S. Rafeeq..</p>
            <p className="co-founder">Co-Founder</p>
          </div>
        </div>
        <img
          src="https://digiwave.online/images/author-image.webp"
          alt="author-img"
          className="author-img-lg"
        />
      </div>
      <Footer />
    </>
  );
};
export default About;
